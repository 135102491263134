import React from "react";
import { NavLink } from "react-router-dom";

export default function Ourservices() {
  return (
    <>
      <div
        className="div-padding purple-bg"
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <h2 className="div-heading mt-5" data-aos="flip-left">
            Our Services
          </h2>
          <div className="">
            <div className="row">
              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="free-medical-coding"
              >
                <div className="company-list card border-0 rounded shadow bg-white">
                  <div className="text-center py-5 border-bottom">
                    <img
                      src="/Images/FreeMedicalBilling.png"
                      className="avatar avatar-large mx-auto d-block mb-3"
                      alt=""
                    />
                    <h4 className="Card-title mt-1 mb-0">
                      Medical Billing
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="medical-billing"
              >
                <div className="company-list card border-0 rounded shadow bg-white">
                  <div className="text-center py-5 border-bottom">
                    <img
                      src="/Images/MedicalBilling.png"
                      className="avatar avatar-large mx-auto d-block mb-3"
                      alt=""
                    />
                    <h4 className="Card-title mt-1 mb-0">
                      Medical Billing & Coding Audit
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="medical-coding"
              >
                <div data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/MedicalCoding.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">Medical Coding</h4>
                    </div>
                  </div>
                </div>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="medical-scribe-service"
              >
                <div data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/MedicalScribe.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">
                        Medical Scribe Services
                      </h4>
                    </div>
                  </div>
                </div>
              </NavLink>

              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="credentialing-services"
              >
                <div className="" data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/Credentialing-Services.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">
                        Credentialing Services
                      </h4>
                    </div>
                  </div>
                </div>
              </NavLink>

              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="virtual-assistant-services"
              >
                <div className="" data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/VirtualAssistant.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">
                        Virtual Assistant Services
                      </h4>
                    </div>
                  </div>
                </div>
              </NavLink>

              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="claim-denial-management"
              >
                <div className="" data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/ClaimDenialManagement.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">
                        Claim Denial Management
                      </h4>
                    </div>
                  </div>
                </div>
              </NavLink>

              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="pre-authorization-services"
              >
                <div className="" data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/AuthService.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">
                        Pre-Authorization Services
                      </h4>
                    </div>
                  </div>
                </div>
              </NavLink>

              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="eligibility-verification"
              >
                <div className="" data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/Verification.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">
                        Eligibility Verification
                      </h4>
                    </div>
                  </div>
                </div>
              </NavLink>
              <div className="col-md-3 col-12 my-2" data-aos="zoom-in">
                <div className="company-list card border-0 rounded shadow bg-white">
                  <div className="text-center py-5 border-bottom">
                    <img
                      src="/Images/HospitalRevenueCycle.png"
                      className="avatar avatar-large mx-auto d-block mb-3"
                      alt=""
                    />
                    <h4 className="Card-title mt-1 mb-0">
                      Hospital Revenue Cycle Management
                    </h4>
                  </div>
                </div>
              </div>
              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="/"
              >
                <div className="" data-aos="zoom-in">
                  <div className="company-list card border-0 rounded shadow bg-white">
                    <div className="text-center py-5 border-bottom">
                      <img
                        src="/Images/ReducingAdministraticePic.png"
                        className="avatar avatar-large mx-auto d-block mb-3"
                        alt=""
                      />
                      <h4 className="Card-title mt-1 mb-0">
                        Reducing Administrative Burden, Boost Revenue
                      </h4>
                    </div>
                  </div>
                </div>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="/"
              >
              <div className="" data-aos="zoom-in">
                <div className="company-list card border-0 rounded shadow bg-white">
                  <div className="text-center py-5 border-bottom">
                    <img
                      src="/Images/IncomDue.png"
                      className="avatar avatar-large mx-auto d-block mb-3"
                      alt=""
                    />
                    <h4 className="Card-title mt-1 mb-0">
                      Stop Losing Income Due to Billing Errors
                    </h4>
                  </div>
                </div>
              </div>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none",color:"black" }}
                className={"col-md-3 col-12 my-2"}
                to="/"
              >
                 <div className="" data-aos="zoom-in">
                <div className="company-list card border-0 rounded shadow bg-white">
                  <div className="text-center py-5 border-bottom">
                    <img
                      src="/Images/HospitalRe.png"
                      className="avatar avatar-large mx-auto d-block mb-3"
                      alt=""
                    />
                    <h4 className="Card-title mt-1 mb-0">
                      You Can Have Less Work AND More Income
                    </h4>
                  </div>
                </div>
              </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
