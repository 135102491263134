import React from "react";
import Carousel from "./Carousel";
import WhoWeAre from "./WhoWeAre";
import Ourservices from "./Ourservices";
import { NotSure } from "./NotSure";
import MeetOurTeam from "./MeetOurTeam";
import FAQs from "./FAQs";
import { SpecialtiesComponent } from "./OurSpecialties/SpecialtiesComponent";
import { Helmet } from "react-helmet";

export const Home = () => {
  return (
    <div>
      <Helmet>
          <title>PharmaCare billing Solution</title>
          <meta name="description" content="PharmaCare billing Solution is medical billing company in America."/>
        </Helmet>
      <Carousel />
      <WhoWeAre />
      <Ourservices />
      <NotSure />
      {/* <MeetOurTeam /> */}
      <FAQs />
      <SpecialtiesComponent />
    </div>
  );
};
