import React from "react";
import { Helmet } from "react-helmet";

function MedicalBilling() {
  return (
    <div
      className="px-md-5 px-3 mx-md-4 mx-2 white-bg"
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
    >
      <Helmet>
        <title>Medical Billing</title>
        <meta
          name="keywords"
          content="pcaresolutions, Medical Billing, pharmacare billing solution"
        />
        <meta
          name="description"
          content="pcaresolutions, Medical Billing, pharmacare billing solution"
        />
      </Helmet>
      <div className="container contact_div">
        <h2 className="div-heading mt-5" data-aos="flip-left">
          Medical Billing
        </h2>
        <div className="row">
          <div className="col-md-8 col-10 m-auto">
            <img
              className="img-fluid rounded-4"
              src="./Images/VirtaulAssistance.jpg"
              alt=""
            />
            <div className="my-md-5 my-3">
              <p className="f-18-b" data-aos="fade-left">
                Medical billing is a process that involves healthcare providers
                handing over insurance claim statements to insurance companies
                for medical claims processing. The medical billing process can
                be time-consuming and challenging. That's where WiseMeds
                Solutions comes in.
              </p>
              <p className="f-18-b" data-aos="fade-left">
                We have a team of Medical Billing Specialists that can help you
                with your billing requirements so that you can focus on what you
                do best - taking care of your patients. Our knowledge and
                experience in Medical Billing enable us to excel in this field.
                From insurance claim submission to denial management, we can
                handle all aspects of your Medical Billing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicalBilling;
