import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="theme-3">
      <div className="footer-nav-div">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12  p-md-5 p-1">
              <div className="footer-brand">
                <NavLink className="navbar-brand" to="/">
                  <span>
                    <img
                      className="d-lg-block d-none Logo-small-style"
                      src="../Images/Logo.png"
                    />
                    <img
                      className="d-lg-none d-block Icon-style py-2"
                      src="../Images/Logo.png"
                    />
                  </span>
                </NavLink>
                <p className="col-12 f-18-n px-md-3 px-2 mx-md-2 mx-1">
                  We are committed to your success. At Pharmacare Billing
                  Solution, we take a comprehensive, all-inclusive approach to
                  the hospital billing and collection process.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 p-md-5 p-1 d-flex justify-content-md-end justify-content-center">
              <div className="row">
                <div className="footer-content">
                  <div className="h2">Contact Us</div>
                  <div className="">
                    <i className="px-3 fa fa-phone"></i> (276) 582-9648
                  </div>
                  <div className="">
                    <i className="px-3 fa-solid fa-location-dot"></i> 30 N Gould
                    St Ste 22927 Sheridan, WY 82801
                  </div>
                  <div className="">
                    <i className="px-3 fa-solid fa-envelope"></i>{" "}
                    <a style={{textDecoration:"none",color:"black"}} href="mailto:info@pcaresolution.com"> info@pcaresolution.com</a>
                  </div>
                  <div className="">
                    <p className="social-nav-title">Our Social Media Links</p>
                    <ul className="social-nav">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=61551722048847" className="facebook">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://instagram.com/pharmacarebillingllc?igshid=MzMyNGUyNmU2YQ==" className="instagram">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://www.linkedin.com/company/pharmacare-billing-solution-llc/" className="linkedin">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright-div theme-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="d-flex justify-content-end">
                The website is owned and operated by (pcaresolution) &copy; 2023.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
