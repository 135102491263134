import React from "react";
import { Helmet } from "react-helmet";
function MedicalBillingCoding() {
  return (
    <div
      className="px-md-5 px-3 mx-md-4 mx-2 white-bg"
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
    >
      <Helmet>
        <title>
          Free Medical Billing & Coding Audit - pharmaCare billing Solutions
        </title>
        <meta
          name="keywords"
          content="pharmacare billing solutions, Free Medical Billing & Coding Audit, pharmacare billing solution"
        />
        <meta
          name="description"
          content="pharmacare billing solutions, Free Medical Billing & Coding Audit, pharmacare billing solution"
        />
      </Helmet>
      <div className="container contact_div">
        <h2 className="div-heading mt-5" data-aos="flip-left">
          Free Medical Billing & Coding Audit
        </h2>
        <div className="row">
          <div className="col-md-8 col-10 m-auto">
            <img
              className="img-fluid rounded-4"
              src="./Images/Free-Medical-Billing.jpg"
              alt=""
            />
            <div className="my-md-5 my-3">
              <p className="f-18-b" data-aos="fade-left">
                Want a professional billing expert to audit your accounts?
                Pharmacare Billing Solution has brought amazing discount offers
                for all healthcare practitioners. Book a free business
                consultation session with our medical billing experts to examine
                your accounts, medical claims, and the progress of your practice
                revenue cycle management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicalBillingCoding;
